import { React, useState, useEffect, useContext } from "react";
import { Routes, Route } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import logo from "./logo.svg";
import "./App.css";
import { Button, Card } from "reactstrap";
import Accord from "./Accordian";
import Webcard from "./Card";
import Headercard from "./HeaderCard";
import Content from "./Content";
import Footer from "./Footer";
import SendMessages from "./SendMessage";
import axios from "axios";
import Services from "./pages/services";
import Contact from "./pages/contact";
import Home from "./pages/home";
import About from "./pages/about";
import Blog from "./pages/blog";
import Videos from "./pages/videos";
import NavBar from "./navbar";
import BlogTemplate from "./BlogTemplate";
import PromptTemplate from "./PromptTemplate";
import Prompts from "./pages/prompts";
import Register from "./pages/register";
import CommentSection from "./pages/comments";
import ServicesTemplate from "./ServicesTemplate";

const App = () => {
	const [blog_data, setblogData] = useState([]);
	const [prompt_data, setpromptData] = useState([]);

	const [video_data, setvideoData] = useState([]);
	const [videoUrl, setVideoUrl] = useState("");
	const [commentsData, setCommentsData] = useState(null);

	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);
	// Define your service data (replace with your actual data)
	const servicesData = [
		{
			id: 1,
			title: "Human-Centered Skill Programs",
			image: "/s1.png",
			description:
				"Workshops or courses focused on enhancing critical thinking, problem-solving, creativity, communication, and collaboration skills. This directly ties into your emphasis on fostering essential human-centered skills.",
			comp1:
				"Curriculum Integration: Embed activities and projects that foster critical thinking, problem-solving, creativity, communication, and collaboration across various subjects.",
			comp2:
				"Workshops and Training: Organize workshops and training sessions for students and teachers to strengthen these essential human-centered skills.",
		},
		{
			id: 2,
			title: "Digital Literacy Boot Camps",
			image: "/s2.png",
			description:
				"Intensive training programs designed to equip individuals and teams with the digital skills needed to thrive in the modern workplace, including online safety, information literacy, and effective use of digital tools.",
			comp1:
				"Digital Citizenship: Educate students on responsible and ethical online behavior, covering digital etiquette, cyberbullying prevention, and online safety.",
			comp2:
				"Information Literacy: Teach students how to effectively find, evaluate, and utilize information from various online sources.",
			comp3:
				"Media Literacy: Develop students' ability to critically analyze and interpret media messages, including images, videos, and social media content.",
			comp4:
				"Technology Skills: Provide training on basic and advanced computer skills, internet navigation, and the use of productivity tools.",
		},
		{
			id: 3,
			title: "AI Literacy for Professionals",
			image: "/s3.png",
			description:
				"Workshops and seminars tailored to professionals in various industries, providing insights into AI fundamentals, applications, and ethical considerations. This service clearly aligns with your focus on promoting AI literacy.",
			comp1:
				"AI Fundamentals: Introduce students to the basic concepts of artificial intelligence, machine learning, and their real-world applications.",
			comp2:
				"Ethical Considerations: Discuss the ethical implications of AI, including bias, privacy, and transparency.",
			comp3:
				"AI and Society: Explore the potential impact of AI on various aspects of society, such as education, employment, and the economy.",
			comp4: "",
		},
		{
			id: 4,
			title: "Digital Wellbeing Consultations",
			image: "/s4.png",
			description:
				" Offer individual or group sessions to help people develop healthy habits around technology use, manage screen time, and create a balanced relationship with the digital world. This directly supports your goal of achieving digital well-being.",
			comp1:
				"Healthy Technology Habits: Teach students about the importance of balanced technology use, time management, and mindful engagement with digital devices.",
			comp2:
				"Stress Management: Provide strategies for managing stress and anxiety related to technology use.",
			comp3:
				"Online Relationships: Foster healthy online relationships and communication skills.",
			comp4: "",
		},
	];

	const getVideoData = async () => {
		try {
			const response = await axios.get("data/videos.json");
			const mydata = response.data;

			setvideoData(mydata);
		} catch (error) {
			console.error(error);
		}
	};

	const getBlogData = async () => {
		try {
			const response = await axios.get("data/posts.json");
			const mydata = response.data;

			setblogData(mydata);
		} catch (error) {
			console.error(error);
		}
	};

	const getPromptData = async () => {
		try {
			const response = await axios.get("data/prompts.json");
			const mydata = response.data;

			setpromptData(mydata);
		} catch (error) {
			console.error(error);
		}
	};

	// Fetch comments using Axios (similar to getVideoData)
	const fetchComments = async () => {
		setIsLoading(true);
		setError(null);

		try {
			const response = await axios.post(
				"https://youtubecomments.vercel.app/get_comments",
				{
					video_url: videoUrl,
				},
			);

			if (response.status !== 200) {
				throw new Error("Failed to fetch comments");
			}

			const comments = response.data;

			setCommentsData({ comments });
			console.log(commentsData);
		} catch (error) {
			console.error("Error fetching comments:", error);
			setError("An error occurred while fetching comments.");
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		getVideoData();
		getBlogData();
		getPromptData();
	}, []);
	return (
		<>
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					minHeight: "100%",
				}}>
				<div style={{ flex: "1" }}>
					<NavBar />

					<Routes>
						<Route path="/" element={<Home />} />
						<Route path="/about" element={<About />} />
						<Route
							path="/services"
							element={<Services servicesData={servicesData} />}
						/>
						<Route path="/contact" element={<Contact />} />
						<Route path="/register" element={<Register />} />
						<Route path="/blog" element={<Blog data={blog_data} />} />
						<Route
							path="/blog/:postId"
							element={<BlogTemplate data={blog_data} />}
						/>
						{/*A nested route!*/}
						<Route path="/videos" element={<Videos videodata={video_data} />} />
						<Route path="/prompts" element={<Prompts data={prompt_data} />} />
						<Route
							path="/prompts/:postId"
							element={<PromptTemplate data={prompt_data} />}
						/>
						<Route
							path="/comments"
							element={
								<CommentSection
									data={commentsData}
									fetchComments={fetchComments}
									videoUrl={videoUrl}
									setVideoUrl={setVideoUrl}
									isLoading={isLoading}
									error={error}
								/>
							}
						/>
						<Route
							path="/services/:serviceId"
							element={<ServicesTemplate servicesData={servicesData} />}
						/>
					</Routes>
				</div>
				<Footer />
			</div>
		</>
	);
};

export default App;
